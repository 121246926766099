//Use when necessary
ul,ol{
	list-style: none;
	margin:0;
	li{
		position: relative;
		padding-left: 15px;
		margin-bottom: 10px;
		&::before{
			content: '-';
			position: absolute;
			left: 0;
		}
	}
	li,a{
		font-weight: 300;
		font-size: rem-calc(16);
	}
}
