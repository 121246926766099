$lg: 580px;

#instafeed {
	width: 100%;
	margin: auto;
	@extend .medium-up-3;
	@extend .grid-x;
	@extend .grid-padding-x;
	a {
		margin-bottom: rem-calc(30);
		@extend .cell;
		img {
			display: block;
			width: 100%;
		}
	}
}
