//Sticky footer
.page__container{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding-top:rem-calc(65);
	@include breakpoint(large){
		padding-top:rem-calc(100);
	}
}
.page__content{
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
}

footer {
	flex-shrink: 0;
}
.block__two-column-with-image {
	.cell--image-container {
		img {
			width: 100%;
		}
	}
	@include breakpoint(small only){
		padding-top:0 !important;
		padding-bottom:0 !important;
	}
}
