.banner{
	position: relative;
	padding:rem-calc(40px 0);
	@include breakpoint(medium) {
		padding: rem-calc(75px 0);
	}
	@include breakpoint(large) {
		padding: rem-calc(100px 0);
	}
	&__medium{
		padding:rem-calc(60px 0);
		@include breakpoint(medium) {
			padding: rem-calc(100px 0);
		}
		@include breakpoint(large) {
			padding: rem-calc(200px 0);
		}
	}
	&__large{
		padding:rem-calc(80px 0);
		@include breakpoint(medium) {
			padding: rem-calc(150px 0);
		}
		@include breakpoint(large) {
			padding: rem-calc(300px 0);
		}
	}
	&__full{
		min-height: 100vh;
		padding:rem-calc(80px 0);
		display: flex;
		@include breakpoint(medium) {
			padding: rem-calc(150px 0);
		}
		@include breakpoint(large) {
			padding: rem-calc(300px 0);
		}
		.grid-container{
			align-self:center;
		}
	}
	.grid-container,
	.grid-x{
		height: 100%;
	}
	/*@include text-spects{
		color: $white;
	}*/
}