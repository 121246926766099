$primary: #212F39;
$secondary: #FFD4CF;
$grey: #F0F0F0;
$white: #FFFFFF;
$black: #000000;
$colors: (
		'primary' : $primary,
		'secondary' : $secondary,
		'grey' : $grey,
		'white' : $white,
		'black' : $black,
);

//FONTS
$font-ubuntu: 'Ubuntu', sans-serif;
$font-merriweather: 'Merriweather', serif;
