//Use when necessary
p{
	font-weight: 300;
}

/*p{
	font-size: rem-calc(16);
	color:$body-font-color;
	font-family: $body-font-family;
	margin-bottom: rem-calc(6px);
	@include breakpoint(medium) {
		margin-bottom: rem-calc(16px);
	}
	&:last-child{
		margin-bottom: 0;
	}
	a{
		@include breakpoint(small only){
			white-space: nowrap;
			margin-bottom: rem-calc(20px);
		}
		margin-right: rem-calc(15px);
		@include breakpoint(medium) {
			margin-right: rem-calc(35px);
		}
		&:last-child{
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}*/
