body.showBlockNames [data-block]:before {
	content: attr(data-block);
	position: absolute;
	left: 0;
	background-color: green;
	color: white;
	padding: 4px;
	z-index: 99999;
}
.absolute {
	position: absolute;
}
.relative {
	position: relative;
}
.dp-block {
	display: block;
}
.inline-block {
	display: inline-block;
}
.uppercase {
	text-transform: uppercase;
}

.valign-middle {
	vertical-align: middle;
}
.strong, .bold {
	font-weight: 700;
}

.align-child-center{
	display: flex;
	align-items: center;
	justify-content: center;
}

.full-height{
	height: 100%;
}

.full-width{
	width: 100%;
}

.cover-bg{
	background-position: center center;
	background-size: cover;
    .child-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        left: 0;
    }
    &.child-image-is-loaded {
        .child-image {
            display: none;
        }
    }
}
img[data-src] {
	@include transition(all);
	transition-duration: 300ms;
	&:not(.image-loaded) {
		filter: blur(10px);
		width: 100%;
	}
}
.image-loaded{
	+img{
		@include transition(all);
		transition-duration: 150ms;
		opacity: 0;
	}
}

.img--thumb{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	filter: blur(10px);
}

.child-image{
	filter: blur(10px);
}

.child-image-is-loaded{
	.child-image{
		transition: all ease-out 150ms;
		opacity: 0;
	}
}
