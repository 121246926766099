@each $colorName, $colorValue in $colors{
	.button--#{$colorName}{
		@include transition(all);
		padding: $button-padding;
		display: inline-block;
		@if $colorName == 'white' {
			color: $primary-color;
		}@else {
			background:$colorValue;
			color:$white;
		}
		&:hover,&:focus{
			background:#{$colorValue};
			@if $colorName == 'white' {
				color: $primary-color;
			}@else {
				color: $white;
			}
		}
		&.hollow {
			background: transparent !important;
			border-color: $colorValue;
			color: $colorValue;
			&:hover,&:focus {
				border-color: $colorValue;
				color: $colorValue;
			}
		}
	}
}

.read-more{
	@include transition(all);
	position: relative;
	padding-left:rem-calc(50);
	font-size: rem-calc(18);
	font-family: $header-font-family;
	cursor: pointer;
	&::before{
		@include transition(all);
		@include vertical-center;
		content: '';
		width:rem-calc(40);
		background: $secondary;
		height: 2px;
		display: block;
		left: 0;
	}
	&:hover{
		padding-left:rem-calc(70);
		font-weight: 700;
		&::before{
			width:rem-calc(60);
		}
	}
}
