.full-header{
	height: 100%;
	flex: 1 0 auto;
	display: flex;
	width: 100%;
	flex-direction: column;
	>div{
		width: 100%;
		flex: 1 0 auto;
	}
	@include breakpoint(small){
		.cover-bg{
			&::after{
				display: block;
				content: " ";
				width: 100%;
				padding-top: 75%;
				position: relative !important;
			}
			&::before {
				display: none;
			}
		}
	}
}
