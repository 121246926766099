.main-nav{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 15;
	background: $white;
	border-bottom:1px solid $grey;
	@include breakpoint(large){
		padding:rem-calc(0 45);
	}
	@include breakpoint(medium down) {
		height: rem-calc(65);
		text-align: center;
	}
	.logo{
		display: flex;
		float: left;
		@include breakpoint(medium down){
			float: none;
			display: inline-block;
			margin:0 auto;
			height: rem-calc(65);
			svg{
				width: rem-calc(150);
			}
		}
	}
	.main-menu{
		margin:0;
		li{
			margin: 0;
			&::before{
				display: none;
			}
			display: inline-block;
			+li{
				margin-left: rem-calc(50);
			}
			a{
				display: inline-block;
				padding:rem-calc(37 4);
				font-family: $body-font-family;
				font-weight: 400;
				letter-spacing: rem-calc(1);
				position: relative;
				&::before{
					@include transition(opacity);
					position: absolute;
					top: 0;
					height: rem-calc(4);
					width: 100%;
					background: $secondary;
					content: '';
					opacity: 0;
				}
			}
			&.is-active,&:hover{
				a{
					&::before{
						opacity: 1;
					}
				}
			}
		}
	}
}

.menu-button {
	@include vertical-center;
	width:  rem-calc(40);
	height: rem-calc(40);
	border-radius: 0;
	padding: 0;
	right: rem-calc(20);
	cursor: pointer;
	.menu-icon {
		position: absolute;
		left: rem-calc(11);
		top: rem-calc(9);
		span {
			opacity: 1;
			display: block;
			width: 18px;
			height: 2px;
			border-radius: 2px;
			background: $dark-gray;
			margin: 4px 0;
			@include transition(all);
		}
	}
	.icon {
		position: absolute;
		top: 12px;
		left: 12px;
		color: $white;
		opacity: 0;
		@include transition(all);
		width: 16px;
		height: 16px;
		&.icon-cross {
			background-image: url('../images/icon-cross.svg');
			background-size: cover;
		}
	}
}
.off-canvas{
	.menu-wrapper{
		padding-top:80px;
		li{
			a{
				font-weight: 500;
			}
			&.active,&:hover{
				a{
					color: $secondary;
				}
			}
		}
	}
}

.is-off-canvas-open{
	.menu-button{
		.menu-icon{
			opacity: 0;
		}
		.icon{
			opacity: 1;
		}
	}
}
