@import "banner";
@import "full-header";

$headerSpaceSize:rem-calc(100);

header{
	@include breakpoint(medium){
		.image-container{
			overflow: hidden;
			margin-bottom:rem-calc(100);
		}
	}
	>div{
		>:nth-child(1):not(.image-container){
			position: relative;
			@include breakpoint(medium){
				padding-left: 50px;
			}
			@include breakpoint(large){
				padding-left: 100px;
			}
			&::before{
				background: $grey;
				width: $headerSpaceSize;
				height: 100%;
				content: '';
				position: absolute;
				top: 0;
				right: -$headerSpaceSize;
				z-index: 10;
			}
		}
		>:nth-child(2):not(.image-container){
			position: relative;
			&::before{
				background: $grey;
				width: $headerSpaceSize;
				height: 100%;
				content: '';
				position: absolute;
				top: 0;
				left: -$headerSpaceSize;
				z-index: 10;
			}
		}
	}
}
