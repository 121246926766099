footer{
	background:$primary;
	position: relative;
	z-index: 1;
	padding:rem-calc(21 0);
	width: 100%;
	@include text-spects{
		color:$white;
	}
	p{
		margin:0;
		font-size: rem-calc(12);
	}
	a{
		color:$white;
		text-decoration: underline;
		&:hover{
			color:$secondary;
		}
	}
}
